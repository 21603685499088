import { combineReducers } from 'redux';
import customizationReducer from './customizationReducer';
import InsightFilterSlice from './slices/InsightFilterSlice';
import AssetFilterSlice from './slices/assetFilterSlice';
import stubDataSlice from './slices/stubDataSlice';
import PageSlice from './slices/PageSlice';
import tableDataSlice from './slices/tableDataSlice';
import AccessDataSlice from './slices/AccessSlice';
import ReportBuilderSlice from './slices/ReportBuilderSlice';
import FileUploadSlice from './slices/FileUploadSlice';
import UserListSlice from './slices/UserListSlice';
import UniqueDraftSlice from './slices/UniqueDraftSlice';
import dataSelectSlice from './slices/dateSelectSlice';
import TableFilterSlice from './slices/TableFilterSlice';
import InsightsDataSlice from './slices/InsightsSlice';
import AppUsageSlice from './slices/AppUsageSlice';
//import DecisionTrackerSlice from './slices/DecisionTrackerSlice';
import UserListAdminAppsSlice from './slices/UserListAdminAppsSlice';
import analysisChartsSlice from './slices/AnalysisChartsSlice';
import LatestCardDetailSlice from './slices/LatestCardDetailSlice';
import AdminOnboardingSlice from './slices/AdminOnboardingSlice';
import DecisionDetailSlice from './slices/DecisionDetailSlice';
import LinkageOverviewSlice from './slices/LinkageOverviewSlice';
import AskAsatoPost from './slices/AskAsatoPost';
import AskAsatoGet from './slices/AskAsatoGet';
import InsightHighlightSlice from './slices/InsightHighlightSlice';
import NotificationSlice from './slices/NotificationSlice';
import AllUserSlice from './slices/AllUsersList';
import DecisionTrackerFilterSlice from './slices/DecisionTrackerFilterSlice';
import StepperSlice from './slices/StepperSlice';
import uniqueServiceSlice from './slices/uniqueServiceSlice';
import InputSlice from './slices/InputSlice';
import ServiceListSlice from './slices/ServiceListSlice';
import SSOLogin from './slices/SSOLogin';
import SourceTableSlice from './slices/SourceTableSlice';
import JobDetailsSlice from './slices/JobDetailsSlice';
import BannerDetailsSlice from './slices/BannerDetailsSlice';
import insightsTableSlice from './slices/InsightTableSlice';
import ManualUploadTableSlice from './slices/ManualUploadTableSlice';
import MainServicesSlice from './slices/MainServicesSlice';
import EditUniqueServiceSlice from './slices/EditUniqueServiceSlice';

const reducer = combineReducers({
  customization: customizationReducer,
  InsightFilter: InsightFilterSlice,
  AssetFilter: AssetFilterSlice,
  stubData: stubDataSlice,
  page: PageSlice,
  tableData: tableDataSlice,
  users: AccessDataSlice,
  reports: ReportBuilderSlice,
  file: FileUploadSlice,
  ReportUser: UserListSlice,
  draft: UniqueDraftSlice,
  dateSelect: dataSelectSlice,
  TableFilter: TableFilterSlice,
  insights: InsightsDataSlice,
  appUsage: AppUsageSlice,
  adminUserList: UserListAdminAppsSlice,
  analysisCharts: analysisChartsSlice,
  cardDetailTable: LatestCardDetailSlice,
  adminOnboarding: AdminOnboardingSlice,
  decisionDetail: DecisionDetailSlice,
  linkageOverView: LinkageOverviewSlice,
  asatoPost: AskAsatoPost,
  asatoGet: AskAsatoGet,
  insightHighlight: InsightHighlightSlice,
  notifications: NotificationSlice,
  allUsers: AllUserSlice,
  decisionFilter: DecisionTrackerFilterSlice,
  stepper: StepperSlice,
  serviceList: ServiceListSlice,
  mainServices: MainServicesSlice,
  inputForms: uniqueServiceSlice,
  editforms: EditUniqueServiceSlice,
  inputs: InputSlice,
  SSOLogin: SSOLogin,
  // Temp
  sources: SourceTableSlice,
  jobDetails: JobDetailsSlice,
  bannerDetails: BannerDetailsSlice,
  insightsTable: insightsTableSlice,
  uploadTable: ManualUploadTableSlice
});

export default reducer;
