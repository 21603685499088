export const configuration = {
  // apiBaseUrl: `https://applicationservice-dev.azurewebsites.net/api/v1/`,
  // apiBaseUrl: `https://application-service-test.azurewebsites.net/api/v1/`,
  insightsDevBaseUrl: import.meta.env.VITE_INSIGHTS_DEV_URL,
  apiBaseUrl: import.meta.env.VITE_API_URL,
  newApiBaseUrl: import.meta.env.VITE_KEYCLOAK_URL,
  askAsato: import.meta.env.VITE_ASK_ASATO,
  askAsatoAutoComplete: import.meta.env.VITE_ASK_ASATO_AUTOCOMPLETE,
  adminURL: import.meta.env.VITE_ADMIN_URL,
  // askAsatoAutoComplete: 'http://127.0.0.1:5000/',
  fileLimit: 10240, //10 MB
  allowedFileFormats: '.txt,.pdf,.doc,.docx,.xls,.xlsx,.png,.jpg,.jpeg,.gif,.csv',
  version: '1.0.0'
};
