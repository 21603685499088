import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0
};

const StepperSlice = createSlice({
  name: 'Stepper',
  initialState,
  reducers: {
    SetStep: (state) => ({
      ...state,
      activeStep: Number(state.activeStep) + 1
    }),
    backStep: (state) => ({
      ...state,
      activeStep: Number(state.activeStep) - 1
    }),
    clearStep: (state) => ({
      ...state,
      activeStep: 0
    })
  }
});

export const { SetStep, backStep, clearStep } = StepperSlice.actions;
export default StepperSlice.reducer;
