import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: '',
  role: '0',
  isSettingPage: false,
  bannerId: '',
  bannerType: ''
};

const pageSlice = createSlice({
  name: 'Pagename',
  initialState,
  reducers: {
    clearPageState: () => {
      return initialState;
    },
    changePage: (state, action) => {
      console.log('page changed');
      return { ...state, page: action.payload };
    },
    changeRole: (state, action) => {
      return { ...state, role: action.payload };
    },
    changeToSettingPage: (state, action) => {
      return { ...state, isSettingPage: action.payload };
    },
    changeBannerId: (state, action) => {
      return { ...state, bannerId: action.payload };
    },
    changeType: (state, action) => {
      return { ...state, bannerType: action.payload };
    }
  }
});

export const { changePage, changeRole, changeToSettingPage, clearPageState, changeBannerId, changeType } = pageSlice.actions;
export default pageSlice.reducer;
